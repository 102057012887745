import React from 'react'
import { Link } from 'gatsby'

const LineItem = ({ line_item }) => {
	const variantImage = line_item.variant.image ? (
		<img
			src={line_item.variant.image.src}
			alt={`${line_item.title} product shot`}
			height="90px"
		/>
	) : null
	return (
		<div className="lineItem">
			<div className="lineItem__header">
        <h4 className="lineItem__title u-h4">
          {line_item.title}
          {`  `}
          {line_item.variant.title === !'Default Title'
            ? line_item.variant.title
            : ''}
        </h4>
      </div>
		  <div className="row">
			<div className="col-6">
			  {variantImage}
			</div>
			<div className="col-6 d-flex flex-column justify-content-between">
			  <p className="lineItem__priceList gradient-text">
			  	{`${line_item.quantity} x ${line_item.variant.price}`}
			  </p>
			</div>
		  </div>
		</div>
		// <div className="d-flex align-center flex-around line-item">
		// 	{variantImage}
		// 	<div className="line-item-right-half">
		// 		<div className="line-item-title tr">
		// 			{line_item.title}
		// 			{`  `}
		// 			{line_item.variant.title === !'Default Title'
		// 				? line_item.variant.title
		// 				: ''}
		// 		</div>
		// 		<div className="line-item-price tr">{`${line_item.quantity} x ${line_item.variant.price}`}</div>
		// 	</div>
		// </div>
	)
}

export default LineItem
