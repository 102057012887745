import React, { useContext, useEffect, useState } from 'react'
import { injectStripe } from 'react-stripe-elements'
import Cookies from 'js-cookie'
import SiteContext from '../../context/StoreContext'
import {
  getShippingRates,
  getPaymentIntent,
  getSetupIntent,
  createStripeCustomer,
  createShopifyCustomer,
  authenticateShopifyCustomer,
  getShopifyCustomer,
  createShopifyOrder,
  completeShopifyOrder,
  calculateShopifyOrder,
} from '../../helpers/requests'
import { calculateShipping } from '../../helpers'
import './styles.scss'
import CheckoutForm from './CheckoutForm'
import LineItem from './LineItem'

const Checkout = props => {
  const {
    store: {
      checkout: {
        lineItems,
        lineItemsSubtotalPrice,
        subtotalPrice,
        totalTax,
        totalPrice,
        totalPriceV2,
        discountApplications,
      },
    },
  } = useContext(SiteContext)
  const [subscriptionItems, setSubscriptionItems] = useState([])
  const [paymentStatus, setPaymentStatus] = useState('idle')
  const [shipping, setShipping] = useState(5)
  const [freeShipping, setFreeShipping] = useState(false)
  const [taxes, setTaxes] = useState(0)
  const [orderCompleted, setOrderCompleted] = useState(false)
  const [registrationStatus, setRegistrationStatus] = useState(false)
  const customerAccessToken = Cookies.get('KEPT_SESS')
  const [customer, setCustomer] = useState(null)
  const [orderId, setOrderId] = useState(null)
  const [activePage, setActivePage] = useState(1)
  const [addressValues, setAddressValues] = useState({})
  const [paymentErrorMessage, setPaymentErrorMessage] = useState('')
  useEffect(() => {
    getShopifyCustomer(customerAccessToken).then(res => {
      if (res.data.data.customer) {
        setCustomer(res.data.data.customer)
      }
    })
  }, [])

  useEffect(() => {
    setSubscriptionItems(
      lineItems.filter(item => {
        return item.customAttributes.filter(attr => {
          return attr.key === 'subscription_product'
        }).length
      })
    )
  }, [lineItems])

  useEffect(() => {
    // if there's any subscription items, an account is necessary to keep track of them
    if (subscriptionItems.length) {
      setRegistrationStatus('NEEDS_ACCOUNT')
    }
    if (customerAccessToken) {
      setRegistrationStatus('HAS_ACCOUNT')
    }
  }, [subscriptionItems])

  // 01. CREATES SHOPIFY CUSTOMER (NECESSARY IF SUBSCRIPTION)
  const createShopifyCustomerFn = async values => {
    console.log(values, registrationStatus)
    if (registrationStatus === 'NEEDS_ACCOUNT') {
      console.log('asd')
      return createShopifyCustomer(values.email, values.password)
    } else {
      console.log('zxcv')
      return null
    }
  }
  // 02. CALCULATES ORDER AMOUNT (ALSO USED TO CALCULATE TAXES AFTER CUSTOMER ENTERS SHIPPING)
  const calculateShopifyOrderFn = async values => {
    console.log(customer, values)
    const trueShipping = calculateShipping(shipping, freeShipping)
    const res = await calculateShopifyOrder(
      values,
      customer,
      lineItems,
      trueShipping,
      discountApplications,
      lineItemsSubtotalPrice.amount,
      subtotalPrice
    )
    console.log('DRAFTORDERCALCULATE RES: ', res)
    return res.data.data.draftOrderCalculate.calculatedDraftOrder
  }

  // 03. ACTUALLY CHARGES THE CARD IN STRIPE:
  const submitPayment = async (values, totalPrice) => {
    setPaymentStatus('processing')
    // first, charge the card for the initial order:
    console.log('initial order')
    const paymentIntent = await getPaymentIntent(totalPrice)
    const client_secret = paymentIntent.data
    return props.stripe.confirmCardPayment(`${client_secret}`, {
      payment_method: {
        card: props.elements.getElement('card'),
        billing_details: {
          name: `${values.firstName} ${values.lastName}`,
        },
      },
    })
  }
  const setUpStripeSubscription = async values => {
    console.log('subscription payments')
    const setupIntent = await getSetupIntent()
    const client_secret = setupIntent.data
    props.stripe
      .confirmCardSetup(`${client_secret}`, {
        payment_method: {
          card: props.elements.getElement('card'),
          billing_details: { name: `${values.firstName} ${values.lastName}` },
        },
      })
      .then(function(result) {
        if (result.error) {
          // Display error.message in your UI.
        } else {
          createStripeCustomer(
            result.setupIntent,
            values.email,
            subscriptionItems,
            customer.id
          ).then(res => {
            if (res.error) {
              console.log('error: ', result.error)
              setPaymentStatus('error')
            } else {
              console.log('sub payment setup success')
            }
          })
          // The setup has succeeded. Display a success message.
        }
      })
  }

  // 04. CREATE SHOPIFY ORDER
  const createShopifyOrderFn = async values => {
    const trueShipping = calculateShipping(shipping, freeShipping)
    const res = await createShopifyOrder(
      values,
      customer,
      lineItems,
      trueShipping,
      discountApplications,
      lineItemsSubtotalPrice.amount,
      subtotalPrice
    )
    console.log('DRAFTORDERCREATE RES: ', res)
    return res.data.data.draftOrderCreate.draftOrder
  }

  // 05. COMPLETE SHOPIFY ORDER
  const completeShopifyOrderFn = id => {
    completeShopifyOrder(id).then(res => {
      console.log('ORDER COMPLETE RES: ', res)
      setOrderCompleted(true)
    })
  }
  // 06. FINISHES:
  useEffect(() => {
    if (orderCompleted) {
      console.log('success!!!')
      window.location.href = '/order-completed'
    }
  }, [orderCompleted])

  const submitFn = async () => {
    console.log(registrationStatus)
    try {
      const order = await calculateShopifyOrderFn(addressValues)
      console.log('DO: ', order)
      const payment = await submitPayment(addressValues, order.totalPrice)
      console.log(payment)
      if (payment.error) {
        console.log('error: ', payment.error)
        setPaymentStatus('error')
        throw new Error(payment.error.message)
        // Display error.message in your UI.
      } else {
        console.log('yay')
        if (subscriptionItems.length) {
          await setUpStripeSubscription(addressValues)
        }
        const createdOrder = await createShopifyOrderFn(addressValues)
        await completeShopifyOrderFn(createdOrder.id)
      }
    } catch (err) {
      console.log(err)
      setPaymentErrorMessage(err.message)
      setTimeout(() => setPaymentErrorMessage(''), 10000)
    }
  }
  const saveAddressFn = async (values, { setSubmitting }) => {
    try {
      const createdCustomer = await createShopifyCustomerFn(values)
      if (createdCustomer) {
        console.log('CUST RES: ', createdCustomer)
        setCustomer(createdCustomer.data.data.customerCreate.customer)
        authenticateShopifyCustomer(values.email, values.password).then(res => {
          // set cookie with access token / exp.date
          console.log('CUST AUTH RES: ', res)
          const { customerAccessTokenCreate } = res.data.data
          if (!customerAccessTokenCreate.userErrors.length) {
            const {
              accessToken,
              expiresAt,
            } = customerAccessTokenCreate.customerAccessToken
            const expiryDate = new Date(expiresAt)
            Cookies.set('KEPT_SESS', accessToken, { expires: expiryDate })
          }
          // todo: add the address to the customer acct.
        })
      }
      const draftOrder = await calculateShopifyOrderFn(values)
      console.log('DO: ', draftOrder)
      setAddressValues(values)
      setTaxes(Number(draftOrder.totalTax))
      setActivePage(2)
      if (values.countryCodeV2 === 'US' || values.countryCodeV2 === 'CA') {
        setShipping(5)
      } else {
        setShipping(10)
      }
    } catch (err) {
      console.log(err.message)
    } finally {
      setSubmitting(false)
    }
  }
  // test cards:
  // 4242424242424242  success
  // 4000002500003155  auth
  // 4000000000009995  fail
  return (
    <>
      <div>
        {paymentErrorMessage.length ? (
          <div class="payment-error">
            {paymentErrorMessage}
            <span onClick={() => setPaymentErrorMessage('')}>&times;</span>
          </div>
        ) : null}
        <div className="checkout-container relative" id="checkout">
          <div className="checkout-form-container">
            <CheckoutForm
              submitFn={submitFn}
              saveAddressFn={saveAddressFn}
              paymentStatus={paymentStatus}
              customer={customer}
              registrationStatus={registrationStatus}
              activePage={activePage}
              setActivePage={setActivePage}
              setFreeShipping={setFreeShipping}
            />
          </div>
          <div className="border-gradient" />
          <div className="checkout-items">
            <h3 className="u-h3">Cart Items</h3>
            <div>
              {lineItems.map((item, i) => (
                <LineItem key={i} line_item={item} />
              ))}
            </div>
            <div className="totals">
              <div className="total-item">
                <p>Subtotal</p>
                <p>
                  {lineItemsSubtotalPrice &&
                  lineItemsSubtotalPrice.amount > subtotalPrice ? (
                    <span>
                      <strike>$ {lineItemsSubtotalPrice.amount}</strike>
                      <br />
                      <span>$ {subtotalPrice}</span>
                    </span>
                  ) : (
                    `$ ${subtotalPrice}`
                  )}
                </p>
              </div>
              <div className="total-item">
                <p>Taxes</p>
                <p>
                  ${' '}
                  {new Intl.NumberFormat('en-US', {
                    style: 'currency',
                    currency: totalPriceV2 ? totalPriceV2.currencyCode : 'USD',
                  })
                    .format(taxes)
                    .replace('$', '')}
                </p>
              </div>
              <div className="total-item">
                <p>Shipping</p>
                <p>
                  {freeShipping ? (
                    <span>
                      <strike>$ {shipping + '.00'}</strike>
                      <br />
                      <span>$ 0.00</span>
                    </span>
                  ) : shipping === 5 ? (
                    '$ 5.00'
                  ) : (
                    '$ 10.00'
                  )}
                </p>
              </div>
              <div className="total-item">
                <p>Total</p>
                <p>
                  ${' '}
                  {new Intl.NumberFormat('en-US', {
                    style: 'currency',
                    currency: totalPriceV2 ? totalPriceV2.currencyCode : 'USD',
                  })
                    .format(
                      Number(totalPriceV2 ? totalPriceV2.amount : 0) +
                        Number(freeShipping ? 0 : shipping) +
                        taxes
                    )
                    .replace('$', '')}
                </p>
              </div>
            </div>
          </div>
          <div class="border-gradient bottom" />
          <div class="mobile-title">
            <h3 className="u-h3">Checkout</h3>
          </div>
        </div>
      </div>
    </>
  )
}

export default injectStripe(Checkout)
