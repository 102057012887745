import React, { Fragment, useState, useEffect } from 'react'
import AddressForm from '../AddressForm/AddressForm'
import CardSection from './CardSection'
import { Formik } from 'formik'
import Loader from 'react-loader-spinner'
import Collapsible from 'react-collapsible'

const CheckoutForm = ({
	submitFn,
	saveAddressFn,
	paymentStatus,
	registrationStatus,
	customer,
	activePage,
	setActivePage,
	setFreeShipping,
}) => {
	return (
		<div className="checkout-form">
			<h2 className="tc no-mobile u-h0">Checkout</h2>
			<Collapsible
				open={activePage === 1}
				handleTriggerClick={() => setActivePage(1)}
				trigger={<span>01. Shipping</span>}
				triggerTagName="div"
			>
				<AddressForm
					customer={customer}
					saveAddressFn={saveAddressFn}
					registrationStatus={registrationStatus}
				/>
			</Collapsible>
			<Collapsible
				open={activePage === 2}
				trigger={<span>02. Payment</span>}
				triggerTagName="div"
				handleTriggerClick={() => console.log('asdf')}
			>
				<div className="payment-form">
					<CardSection setFreeShipping={setFreeShipping} />
					<div className="order-button-container relative">
						<div className="border-gradient order-border-gradient" />
						<button
							className="order-button"
							type="submit"
							onClick={submitFn}
							disabled={paymentStatus === 'processing'}
						>
							{paymentStatus === 'processing' ? (
								<Fragment>
									<Loader
										type="TailSpin"
										color="#855131"
										height={30}
										width={30}
									/>
									<span className="roboto gradient-text-rotated loading">
										Loading...
									</span>
								</Fragment>
							) : (
								<span className="roboto gradient-text-rotated">
									Confirm order
								</span>
							)}
						</button>
					</div>
				</div>
			</Collapsible>
		</div>
	)
}

export default CheckoutForm
