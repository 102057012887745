import React, { useState, useEffect } from 'react'
import Cookies from 'js-cookie'
import InjectedCheckoutForm from '../components/Checkout/Checkout'
import { Elements, StripeProvider } from 'react-stripe-elements'
import SEO from '../components/seo'

const CheckoutPage = ({ data }) => {
	return (
		<div>
			<SEO title="Checkout" />
			<StripeWrapper>
				<Elements>
					<InjectedCheckoutForm />
				</Elements>
			</StripeWrapper>
		</div>
	)
}

const StripeWrapper = ({ children }) => {
	const [stripe, setStripe] = useState(null)

	useEffect(() => {
		// for SSR
		if (typeof window == 'undefined') return
		// for browser
		if (window.Stripe) {
			setStripe(window.Stripe('pk_test_vMNjBlfT5mboHRCje4bJ8rKd00o67r4EKP'))
		} else {
			const stripeScript = document.querySelector('#stripe-js')
			stripeScript.onload = () => {
				setStripe(window.Stripe('pk_test_vMNjBlfT5mboHRCje4bJ8rKd00o67r4EKP'))
			}
		}
	}, []) // <-- passing in an empty array since I only want to run this hook once

	return <StripeProvider stripe={stripe}>{children}</StripeProvider>
}
export default CheckoutPage
